<template>
    <div class="row form-vertical">
                <div class="col-md-4">
                    <h4>Case Properties</h4>
                    <div class="form-group">    
                        <label class="col-md-12 control-label text-left" v-bind:class = "(!changeRequest)?'required':''">Change Request Type <span v-bind:class = "(!changeRequest)?'required':''">(Required)</span></label>
                        <div class="col-12">
                            <input type="text" class="form-control" v-model="changeRequest">
                        </div>
                    </div>
                    <div class="form-group">    
                        <label class="col-md-12 control-label text-left">Delivery Date</label>
                        
                        <DatePicker v-model="date" mode="dateTime" :timezone="timezone">
                            <template v-slot="{ inputValue, inputEvents }">
                            
                            <div class="col-12">
                                <div class="input-group date">
                                <div class="input-group-addon" ng-click="isDatepickerOpenDelivery = !isDatepickerOpenDelivery">
                                    <i class="fa fa-calendar"></i>
                                </div>
                                    <input
                                    class="form-control pull-right"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                    />
                                </div>
                            </div>
                            </template>
                        </DatePicker>
                           
                    </div>  
                    <div class="form-group">    
                        <label class="col-md-12 control-label text-left">Detail</label>
                        <div class="col-12">
                            <textarea class="form-control" rows="3"></textarea>
                        </div>
                    </div> 
                </div> 
                <div class="col-md-4">
                    <h4>Question Properties</h4>
                    <div class="form-group">    
                        <label class="col-md-12 control-label control-label text-left">Quantity</label>
                        <div class="col-12">
                            <input type="text" class="form-control">
                        </div>
                    </div>  
                    <div class="form-group">    
                        <label class="col-md-12 control-label control-label text-left">Team</label>
                        <div class="col-12">
                            <div class="simple-label multiple pos-rel">
                                <div class="float-btn-group">
                                    <button class="margin-5 icon-button button-delete" type="button"><i class="fa fa-times-circle"></i> </button>
                                    <input type="text" class="form-control">
                                </div>
                                <div class="float-btn-group">
                                    <button class="margin-5 icon-button button-delete" type="button"><i class="fa fa-times-circle"></i> </button>
                                    <input type="text" class="form-control">
                                </div>
                                <div class="float-btn-group">
                                    <button class="margin-5 icon-button button-delete" type="button"><i class="fa fa-times-circle"></i> </button>
                                    <input type="text" class="form-control">
                                </div>
                                <button class="margin-5 icon-button button-add-row" type="button"><i class="fa fa-plus-circle"></i> </button>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 control-label control-label text-left">Team (with description)</label>
                                <div class="col-12">
                                    <div class="simple-label multiple pos-rel">
                                    <div class="float-btn-group">
                                        <button class="margin-5 icon-button button-delete" type="button"><i class="fa fa-times-circle"></i> </button>
                                        <div class="input-with-desc">
                                            <select class="form-control group-property-1">
                                            <option value="Fax">Fax</option>
                                            <option value="Home">Home</option>
                                            <option value="Mobile">Mobile</option>
                                        </select>  
                                        <input type="text" class="form-control group-property-2">
                                        </div>  
                                    </div>
                                    <div class="float-btn-group">
                                        <button class="margin-5 icon-button button-delete" type="button"><i class="fa fa-times-circle"></i> </button>
                                        <div class="input-with-desc">
                                        <select class="form-control group-property-1">
                                            <option value="Fax">Fax</option>
                                            <option value="Home">Home</option>
                                            <option value="Mobile">Mobile</option>
                                        </select>  
                                        <input type="text" class="form-control group-property-2">
                                        </div> 
                                    </div>
                                    <div class="float-btn-group">
                                        <button class="margin-5 icon-button button-delete" type="button"><i class="fa fa-times-circle"></i> </button>
                                        <div class="input-with-desc">
                                        <select class="form-control group-property-1">
                                            <option value="Fax">Fax</option>
                                            <option value="Home">Home</option>
                                            <option value="Mobile">Mobile</option>
                                        </select>  
                                        <input type="text" class="form-control group-property-2">
                                        </div> 
                                    </div>
                                    <button class="margin-5 icon-button button-add-row" type="button"><i class="fa fa-plus-circle"></i> </button>
                                    </div>
                                </div>
                                </div>
                        </div>
                    </div>   
                </div>  
                <div class="col-md-4">
                    <h4>Answer Properties</h4>
                    <div class="form-group">
                    <label class="col-md-12 control-label text-left">Priority</label>
                    <div class="col-12">
                        <select class="form-control">
                        <option value="High">High</option>
                        <option value="Low">Low</option>
                        </select>
                    </div>
                    </div>
                    <div class="form-group">
                    <label class="col-md-12 control-label text-left">isOpen</label>
                    <div class="col-12">
                        <select class="form-control">
                        <option value="True">True</option>
                        <option value="False">False</option>
                        </select>
                    </div>
                    </div>
                    <div class="form-group bg-group dib">
                        <label class="col-md-12 control-label text-left">Interaction Property Based Skills</label>
                        <div class="col-md-12">
                        <div class="form-group group-bg group-input detail-g-borderbottom"> 
                            <label class="col-md-12 control-label text-left group-input-label">Interaction Property</label>
                            <div class="col-md-12">
                            <select class="form-control group-input-item ng-valid ng-not-empty ng-dirty ng-valid-parse ng-touched" ng-change="setFocus()" ng-model="myValue" ng-init="myValue = 21;" style="">
                                <option value="21">Answer Property  (QA) (Answer)</option>
                                <option value="2">Authorization Type  (QA) (Question)</option>
                                <option value="42">Carrier Adjument Amount (AR)  (1to1Service) (Case)</option>
                                <option value="48">Case Property</option>
                                <option value="7">Change Designation  (QA) (Question)</option>
                                <option value="6">Change Priority  (QA) (Question)</option>
                            </select>
                            </div>
                        </div>
                        <div class="form-group group-bg group-input detail-g-borderbottom detail-g-bordertop">
                            <label class="col-md-12 control-label text-left  group-input-label">Interaction Property Value</label>
                            <div class="col-md-12">														
                            <input type="text" class="form-control group-input-item" id="propertyValue">
                            </div>
                        </div>
                        <div class="form-group group-bg group-input detail-g-bordertop">
                            <label class="col-md-12 control-label text-left  group-input-label">Interaction Property Value Type</label>
                            <div class="col-md-12">														
                            <input type="text" class="form-control group-input-item" id="propertyValue2">
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="form-group">
                    <label class="col-md-12 control-label text-left">Price</label>
                    <div class="col-12">
                        <input type="number" step="any" class="form-control">
                    </div>
                    </div>
                </div>
                <div class="col-md-12" v-show = " showSaveButton "> {{ displaySave }}
                    <button type="submit" class="btn btn-primary">Save</button>
                </div>
            </div>   
        
</template>

<script>
  var displaySave = true;
  export default {
  name: 'InteractionPropertyTabComponents',
   components: {
      
  },
  props: ['displaySaveButton'],
  data: function(){
      return{
        changeRequest:''
      }
  },
  computed: {
    showSaveButton: function () {
        if (typeof(this.displaySaveButton) != "undefined"){
            return false;
        }else{
            return true;
        }
    }
  }
 

}

</script>